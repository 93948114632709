var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", { attrs: { "head-title": "任务下发列表" } }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowView,
                  "grid-row-detail-click-distribution": _vm.rowDistribution,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row, index }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.rowView(row)
                              },
                            },
                          },
                          [_vm._v("\n            查看\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务下发信息查看",
            "append-to-body": true,
            "close-on-click-modal": false,
            visible: _vm.isReportAllocationDialog,
            width: "70%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isReportAllocationDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _vm.isReportAllocationDialog
            ? _c("reportAllocationDialog", {
                ref: "overseasProjectFillingEdit",
                attrs: { formData: _vm.formData },
                on: { closeDialog: _vm.closeDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }